import cn from "classnames"
import Image from "components/Image"
import richText from "utilities/richText"
import InfoBox from "components/DynamicComponent/atoms/InfoBox"

export default function IntroWithImage({ blok }) {
  return (
    <section
      className={cn("bg-gray-light px-8", {
        "mb-12": blok.info_box || blok.image_offset,
      })}
    >
      <div className="max-w-screen-xl mx-auto lg:flex gap-8">
        <div className="intro-with-image-content content basis-1/2 py-6 xl:py-12 prose-h1:pb-2 prose-h2:text-s3 prose-h2:lg:text-m1 prose-h2:uppercase prose-h2:pb-4 prose-h2:leading-6 prose-p:pb-2 relative">
          {richText(blok.content)}
          {blok.info_box ? <InfoBox /> : null}
        </div>
        <div className="basis-1/2">
          <Image
            src={blok.image.filename}
            alt={blok.image.alt}
            placeholder={blok.image?.blurDataURL ? "blur" : "empty"}
            blurDataURL={blok.image?.blurDataURL}
            width={520}
            height={485}
            className={cn("relative mx-auto w-full lg:float-right", {
              "-bottom-10 -mt-10": blok.image_offset,
            })}
          />
        </div>
      </div>
    </section>
  )
}
