import { useState, useEffect } from "react"
import { useRouter } from "next/router"
import getTelLink from "utilities/getTelLink"
import Phone from "public/assets/phone.svg"
import MapPin from "public/assets/map-pin.svg"
import { useDogtownLocations } from "context/dogtownLocations"

export default function InfoBox() {
  const locations = useDogtownLocations()
  const router = useRouter()

  const [currentLocation, setCurrentLocation] = useState({
    address: "",
    mapsLink: "",
    phone: "",
  })

  useEffect(() => {
    locations.pins.map((location) => {
      if (router.asPath.includes(location.slug.endsWith("/") ? location.slug.slice(0, -1) : location.slug)) {
        setCurrentLocation(location)
      }
    })
  }, [router, locations])

  const iconLink =
    "text-white flex items-center gap-4 text-s3 not-italic underline hover:no-underline font-bold leading-[22px] first:pr-6 last:pl-6 first:basis-7/12 last:basis-5/12"

  return (
    <address className="hidden lg:flex bg-gray-dark py-4 px-8 divide-x-2 xl:absolute w-full -bottom-8">
      <a
        href={currentLocation.mapsLink ?? "#"}
        className={iconLink}
        target={currentLocation.mapsLink ? "_blank" : "_self"}
        rel="noreferrer"
      >
        <MapPin className="w-8 text-white" />
        {currentLocation.address}
      </a>
      <a href={getTelLink(currentLocation.phone.primary) ?? "#"} className={iconLink}>
        <Phone className="h-6 text-white" />
        {currentLocation.phone.primary}
      </a>
    </address>
  )
}
